import { PageType } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLink } from "scmp-app/components/common/base-link";
import { AgendaContent } from "scmp-app/components/plus/home/agenda-block/content";
import { useNewsAgenda } from "scmp-app/components/plus/news-agenda/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { pathname as plusAgendaPathname } from "scmp-app/pages/plus/agenda";
import type { itemAgendaItemTopic$key } from "scmp-app/queries/__generated__/itemAgendaItemTopic.graphql";
type Props = {
  className?: string;
  gaEventType?: "plus_home" | "discovery_module";
  isFirstAgenda?: boolean;
  isFirstKeyEventAgenda?: boolean;
  needToShowUpdatedLabel?: boolean;
  reference: itemAgendaItemTopic$key;
  showAdditionalContent?: boolean;
};

export const AgendaItem: FunctionComponent<Props> = ({
  className,
  gaEventType = "plus_home",
  isFirstKeyEventAgenda,
  needToShowUpdatedLabel,
  reference,
  showAdditionalContent = true,
}) => {
  const data = useFragment(
    graphql`
      fragment itemAgendaItemTopic on Topic {
        urlAlias
        newsAgenda {
          startDate
        }
        entityId
        name
        ...contentAgendaContentTopic
        ...hooksNewsAgendaTopic
      }
    `,
    reference,
  );

  const { date, hasDetailsView: clickable } = useNewsAgenda(data);
  const currentPageType = useCurrentPageType();

  const handleGA4Tracking = () => {
    if (gaEventType === "discovery_module") {
      const pageType = currentPageType === PageType.Homepage ? "homepage" : "section";
      sendGA4Tracking({
        action: "click",
        category: "recirculation",
        customized_parameters: {
          block_section: "new_agenda",
          destination_url: data?.urlAlias ?? "",
          page_type: pageType,
          topic_id: data?.entityId ?? "",
          widget_name: "content_discovery",
        },
        subcategory: "widget",
      });
    } else {
      sendGA4Tracking({
        action: "click",
        category: "menu",
        customized_parameters: {
          agenda_event: data.name,
          agenda_event_date: date ?? "",
          page_type: "homepage",
          topic_id: data.entityId,
        },
        subcategory: "agenda",
      });
    }
  };

  return (
    <>
      {clickable ? (
        <BaseLink
          as={data.urlAlias}
          pathname={plusAgendaPathname}
          query={{
            entityId: data.entityId,
            startDate: data.newsAgenda?.startDate?.toFixed(0) ?? undefined,
          }}
          onClick={handleGA4Tracking}
        >
          <AgendaContent
            className={className}
            isFirstKeyEventAgenda={isFirstKeyEventAgenda}
            needToShowUpdatedLabel={needToShowUpdatedLabel}
            reference={data}
            showAdditionalContent={showAdditionalContent}
          />
        </BaseLink>
      ) : (
        <AgendaContent
          className={className}
          isFirstKeyEventAgenda={isFirstKeyEventAgenda}
          needToShowUpdatedLabel={needToShowUpdatedLabel}
          reference={data}
          showAdditionalContent={showAdditionalContent}
        />
      )}
    </>
  );
};

AgendaItem.displayName = "AgendaItem";
